<template lang="pug">
    .dynamic-content-wrapper
      .dynamic-content-wrapper__text(v-html="content")
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
      name: 'DynamicContent',
      data: () => ({
        content: null,
      }),
      computed: {
        ...mapGetters({
          contentPageType: 'main/getContentPageType',
          language: 'user/getLanguage',
        }),
      },
      methods: {
        ...mapActions({
          fetchContentPage: 'main/fetchContentPage',
        }),
      },
      watch:{
        async language(){
          this.content = await this.fetchContentPage(this.contentPageType);
        }
      },
      async beforeMount() {
        this.content = await this.fetchContentPage(this.contentPageType);
      },
    };
</script>

<style lang="scss">
@import '@/assets/styles/serviceComponents/dynamic_content_sw.scss';
</style>
